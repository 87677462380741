
.stylization .react-datepicker button,
.stylization input.date-picker[type=submit] {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker-wrapper {
    width: 100%
}

.auth-wrap .auth-col button {
    margin: 0 0 0 20%;
	font-size: 13px;
	padding: 10px 20px;
}